import Templates from "./templates";
import Microform from "./microform";

import microformCss from '!!raw-loader!../assets/styles/ipx-microform.css';
import fontAwsomeCss from '!!raw-loader!../assets/styles/font-awsome.css';
import vehiclesTableCss from '!!raw-loader!../assets/styles/vehicles-table.css';

class IpxMicroformComponent extends HTMLElement {

    constructor() {
        super();

        this.root = this.attachShadow({ mode: "open" });
        let baseUrl = this.baseUrl() !== "" && this.baseUrl() !== null ? this.baseUrl() : "https://www.triptender.net";
        let apiUrl = this.apiUrl() !== "" && this.apiUrl() !== null ? this.apiUrl() : "https://beta4.triptender.net/api";
        let resFormPage = this.resFormPage() !== "" && this.resFormPage() !== null ? this.resFormPage() : "https://beta4.triptender.net/reservation.php";
        let googleApiKey = "AIzaSyCLBWfH51GW3eo7cwzci_RgcE9ew8HCRg4";

        const templates = new Templates(baseUrl, apiUrl);

        this.root.innerHTML = `
            <style>
            ${microformCss}
            ${fontAwsomeCss}
            ${vehiclesTableCss}
            </style>
            ${templates.content}
            ${templates.loader}`;

        let microform = new Microform(this.token(), this.player(), baseUrl, apiUrl, googleApiKey, this.fromPoi(), this.toPoi(), this.language(), resFormPage);
        microform.run();
    }

    token() {
        return this.getAttribute("token");
    }

    player() {
        return this.getAttribute("player");
    }

    fromPoi() {
        return this.getAttribute("fromPoi");
    }

    toPoi() {
        return this.getAttribute("toPoi");
    }

    language() {
        return this.getAttribute("language");
    }

    baseUrl() {
        return this.getAttribute("baseUrl");
    }

    apiUrl() {
        return this.getAttribute("apiUrl");
    }

    resFormPage() {
        return this.getAttribute("resFormPage");
    }
}

customElements.define("ipx-microform-component", IpxMicroformComponent);
