// Base class containing common data like baseUrl, apiUrl etc, so this data can be inherited in child classes
export default class BaseMicroformClass {

    constructor(baseUrl, apiUrl, resFormPage) {
        this.baseUrl = baseUrl;
        this.apiUrl = apiUrl;
        this.resFormPage = resFormPage;
    }

    showLoaderAnimation() {
       document.querySelector('ipx-microform-component').shadowRoot.querySelector("#loader").style.display = "block";
    }

    hideLoaderAnimation() {
       document.querySelector('ipx-microform-component').shadowRoot.querySelector("#loader").style.display = "none";
    }

}
