import BaseMicroformClass from "./baseClass";
import MicroformData from "./microformData";
import axios from "axios";
import qs from "qs";
import $ from 'jquery';

export default class Microform extends BaseMicroformClass {

	constructor(token, player, baseUrl, apiUrl, googleApiKey, fromPoi, toPoi, language, resFormPage) {

		if (baseUrl.length === 0) {
			baseUrl = BASE_URL;
		}
		if (apiUrl.length === 0) {
			apiUrl = API_URL;
		}
		if (googleApiKey.length === 0) {
			googleApiKey = GOOGLE_API_KEY;
		}
		if (resFormPage.length === 0) {
			resFormPage = RES_FORM_PAGE;
		}

		super(baseUrl, apiUrl, resFormPage);

		this.token = token;
		this.player = player;
		this.googleApiKey = googleApiKey;
		//this.targetDiv = targetDiv;

		this.targetDiv = document.querySelector('ipx-microform-component').shadowRoot;

		this.fromPoi = fromPoi;
		this.toPoi = toPoi;
		this.language = language;

		// Initialize Microform data. Selected From, To, Vehicles etc.
		this.microformData = new MicroformData();

		axios.defaults.headers.common["app-token"] = token;
		axios.defaults.headers.common["app-player"] = player;

		this.fromMustUpdate = true;
		this.toMustUpdate = true;
	}

	// Methods

	// Async script loading
	async loadScript(url, callback) {
		return $.ajax({
			url: url,
			dataType: "script",
			success: callback,
			async: true
		});
	}

	// AJAX requests wrapper
	async apiRequest(url, data = {}, methodType = "get", dataType = "json") {
		let requestDone = $.Deferred();

		let retVal = {
			success: true,
			data: null,
			errorMessage: ""
		};

		this.showLoaderAnimation();

		const options = {
			method: methodType,
			url: methodType === "get" ? url + "?" + qs.stringify(data) : url,
			responseType: dataType,
			data: methodType !== "get" ? data : null
		};
		const request = await axios(options).catch((error) => {
			retVal = {
				success: false,
				data: undefined,
				errorMessage: error
			};
			console.log("Error executing AJAX request: ", error);
			this.hideLoaderAnimation();
		});

		if (request && request.status === 200) {
			this.hideLoaderAnimation();

			if (request.data) {
				// result found
				retVal = {
					success: true,
					data: request.data,
					errorMessage: ""
				};
			}
			else {
				// result not found
				retVal = {
					success: false,
					data: undefined,
					errorMessage: request
				};
			}
		}

		requestDone.resolve(retVal);
		return requestDone;
	}

	// Find POI cross reference in DB with google result by google"s place_id
	async findCrossReference(placeId) {
		return this.apiRequest(this.apiUrl + "/location/find_cross_ref", { place_id: placeId });
	}

	// Extract address from google autocomplete RAW result
	extractAddress(resultRaw) {
		//console.log(resultRaw);
		const componentForm = {
			street_number: "short_name",
			route: "long_name",
			locality: "long_name",
			administrative_area_level_1: "short_name",
			country: "long_name",
			postal_code: "short_name"
		};

		const {
			address_components,
			formatted_address,
			geometry: { location: { lat, lng } }
		} = resultRaw;
		const addressObject = {
			streetNumber: "",
			streetName: "",
			cityName: "",
			stateAbbr: "",
			zipCode: "",
			coordinates: { lat: lat(), lng: lng() },
			placeId: resultRaw.place_id,
			poiName: resultRaw.name,
			lat: resultRaw.geometry.location.lat(),
			lng: resultRaw.geometry.location.lng()
		};
		// Need to loop over the results and create a friendly object
		for (let i = 0; i < address_components.length; i++) {
			const addressType = address_components[i].types[0];
			if (componentForm[addressType]) {
				switch (addressType) {
					case "street_number":
						addressObject.streetNumber = address_components[i].long_name;
						break;
					case "route":
						addressObject.streetName = address_components[i].long_name;
						break;
					case "locality":
						addressObject.cityName = address_components[i].long_name;
						break;
					case "administrative_area_level_1":
						addressObject.stateAbbr = address_components[i].short_name;
						addressObject.state = address_components[i].long_name;
						break;
					case "postal_code":
						addressObject.zipCode = address_components[i].long_name;
						break;
					case "country":
						addressObject.countryAbbr = address_components[i].short_name;
						addressObject.country = address_components[i].long_name;
						break;
					default:
						break;
				}
			}
		}

		const resultFormatted = Object.assign({}, addressObject, {
			formattedAddress: formatted_address
		});

		return resultFormatted;
	}

	async saveJSON() {
		return this.apiRequest(this.apiUrl + "/operations/save_json", { data: JSON.stringify(this.microformData) }, "post");
	}

	buildVehiclesTable(data) {
		let tableHTML = "<table>";
		data.forEach((fleet) => {
			tableHTML += "<tr vehicle_id=" + fleet.vehicle.rspgte_fleet_id + ">";

			tableHTML += "   <td>";
			tableHTML += "       <img src='" + fleet.vehicle.image.image_loc + "' class='hidden-xs'>";
			tableHTML += "       <div class='car-label-text'>&nbsp;<label class='vehicle_name' for='vehicle_chk_" + fleet.vehicle.rspgte_fleet_id + "' rel='label'>" + fleet.vehicle.rspgte_fleet_name + "</label> <br/> ";
			tableHTML +=           fleet.vehicle.rspgte_fleet_max_pax + " <img src='https://www.triptender.net/images/microform/pax-symbol-16x19.gif'><input id='vehicle_pax_" + fleet.vehicle.rspgte_fleet_id + "' class='vehicle_pax' name='vehicle_pax_" + fleet.vehicle.rspgte_fleet_id + "' value='" + fleet.vehicle.rspgte_fleet_max_pax + "' type='hidden'> ";
			tableHTML +=            fleet.vehicle.rspgte_max_lug + " <img src='https://www.triptender.net/images/microform/luggage_symbol_l-16x19.gif'><input id='vehicle_lug_" + fleet.vehicle.rspgte_fleet_id + "' name='vehicle_lug_" + fleet.vehicle.rspgte_fleet_id + "' value='" + fleet.vehicle.rspgte_max_lug + "' type='hidden'></div>  ";
			tableHTML += "   </td>";
			tableHTML += "   <td>";


			tableHTML += "   </td>";

			tableHTML += "   <td class='price_per_vehicle'>";
			// Price go here
			tableHTML += "   </td>";

			tableHTML += "</tr>";
		});
		tableHTML += "</table>";

		return tableHTML;
	}

	async setVehiclePrices(vehIds) {
		let result = await this.apiRequest(this.apiUrl + "/microform/get_fleets_prices",
			{
				from_lat: this.microformData.trips[0].from.lat,
				from_lng: this.microformData.trips[0].from.lng,
				to_lat: this.microformData.trips[0].to.lat,
				to_lng: this.microformData.trips[0].to.lng,
				from_zip: this.microformData.trips[0].from.zip,
				to_zip: this.microformData.trips[0].to.zip,
				currency: 100,
				fleets: vehIds,
				from_country: this.microformData.trips[0].from.countryAbr,
				to_country: this.microformData.trips[0].to.countryAbr
			});

		if (result.data) {
			let microformClass = this; // this variable holds class"s context
			result.data.forEach((priceData) => {
				let price = priceData.price;
				if (priceData.id !== "") {
					if (price.toString().length > 0) {
						price = "$" + price;
					}
					microformClass.targetDiv
						.querySelector("div.vehicles table")
						.querySelector('tr[vehicle_id="' + priceData.id + '"]')
						.querySelector("td.price_per_vehicle")
						.innerHTML = price;
				}
			});
		}
	}

	// async setVehiclePrices() {
	// 	debugger
	// 	const result = await this.apiRequest(this.apiUrl + '/reservation/get_fleets', 
	// 	{
	// 		trip: 0,
	// 		get_all_veh: 1
	// 	});

	// 	console.log({result});

	// 	if (result.data.vehicles) {
	// 		let microformClass = this; // this variable holds class"s context
	// 		result.data.vehicles.forEach((priceData) => {
	// 			let price = priceData.price.total;
	// 			if (priceData.vehicle.rspgte_fleet_id !== "") {
	// 				if (price.toString().length > 0) {
	// 					price = "$" + price;
	// 				}
	// 				microformClass.targetDiv
	// 					.querySelector("div.vehicles table")
	// 					.querySelector('tr[vehicle_id="' + priceData.vehicle.rspgte_fleet_id+ '"]')
	// 					.querySelector("td.price_per_vehicle")
	// 					.innerHTML = price;
	// 			}
	// 		});
	// 	}
	// }

	async goToResForm() {
		//if (this.microformData.trips[0].from.zip !== "") {
			console.log("Go to reservation form");
			await this.saveJSON();
			window.open(this.microformData.ada.ada_res_form_url + "?uid=" + axios.defaults.headers.common["api-req"], "_self");
			//console.log(this.microformData.ada.ada_res_form_url + "?uid=" + axios.defaults.headers.common["api-req"]);
		/*}
		else {
			setTimeout(() => {
				$(this.targetDiv + " div.vehicles table tr").css("background-color", "");
			}, 500);
		}*/
	}

	async getFleetTypes() {
		if (this.targetDiv.querySelector(".alert-danger")) {
			this.targetDiv.querySelector(".alert-danger").remove();
		}
		console.log("Search for service availability");
		let result = await this.apiRequest(this.apiUrl + "/reservation/get_fleet_types",
			{
				zip: this.microformData.trips[0].from.zip,
				country: this.microformData.trips[0].from.countryAbr
			});

		this.microformData.trips[0].rsp = result.data.rsp;
		delete result.data.rsp;
		result.data = Object.values(result.data);

		if (result.data) {
			if (result.data.length > 0) {
				this.targetDiv.querySelector("div.vehicles").innerHTML = this.buildVehiclesTable(result.data);

				this.addTableRowClickEvent();
			}
			else {
				let div = document.createElement('div');
				div.innerHTML = "No service available";
				div.classList.add("alert");
				div.classList.add("alert-danger");
				microformClass.targetDiv.querySelector("div.footer").insertAdjacentElement('beforebegin', div);
			}
		}
		else {
			if (result.errorMessage.response.data.message) {
				// clear vehicles table
				this.targetDiv.querySelector("div.vehicles").innerHTML = `
					<div class="w3_main_grid_right">
						<div></div>
					</div>
				`;

				let div = document.createElement('div');
				div.innerHTML = result.errorMessage.response.data.message;
				div.classList.add("alert");
				div.classList.add("alert-danger");
				microformClass.targetDiv.querySelector("div.footer").insertAdjacentElement('beforebegin', div);

				this.hideLoaderAnimation();
			}
		}
	}

	async getPoiDetails(poiId) {
		console.log("Find POI details from parameter.");
		let result = await this.apiRequest(this.apiUrl + "/location/get_poi_data",
			{
				poi_id: poiId
			});
		if (result.data && result.data[0]) {
			console.log("POI details found.");

			return result.data[0];
		}
		else {
			console.log("POI details NOT found.");
		}
	}

	async onFromBlur() {
		if (!this.fromMustUpdate) {
			return;
		}
		else {
			this.fromMustUpdate = false;
			if (this.microformData.trips[0].from.zip !== "" && this.microformData.trips[0].to.zip === "") {
				await this.getFleetTypes();
			}
			if (this.microformData.trips[0].from.zip !== "" && this.microformData.trips[0].to.zip !== "") {
				this.toMustUpdate = true;
			}
		}

		this.onToBlur();
	}

	async onToBlur() {
		if (!this.toMustUpdate) {
			return;
		}

		this.toMustUpdate = false;

		if (this.microformData.trips[0].to.zip !== "" && this.microformData.trips[0].from.zip !== "") {
			console.log("Find prices");
			if (this.targetDiv.querySelector(".alert-danger")) {
				this.targetDiv.querySelector(".alert-danger").remove();
			}
			await this.saveJSON();

			if (this.targetDiv.querySelector("div.vehicles table tr").length === 0) {
				await this.getFleetTypes();
			}

			let vehIds = "";
			let elements = this.targetDiv.querySelectorAll("div.vehicles table tr");
			Array.prototype.forEach.call(elements, (row, index) => {
				vehIds += row.attributes["vehicle_id"].value + ",";
			});
			vehIds = vehIds.substr(0, vehIds.length - 1);
			if (vehIds !== "") {
				await this.getFleetTypes();
				this.setVehiclePrices(vehIds);
			}
		}
	}

	// async onToBlur() {
	// 	if (!this.toMustUpdate) {
	// 		return;
	// 	}

	// 	this.toMustUpdate = false;

	// 	if (this.microformData.trips[0].to.zip !== "" && this.microformData.trips[0].from.zip !== "") {
	// 		console.log("Find prices");
	// 		if (this.targetDiv.querySelector(".alert-danger")) {
	// 			this.targetDiv.querySelector(".alert-danger").remove();
	// 		}
	// 		await this.saveJSON();

	// 		if (this.targetDiv.querySelector("div.vehicles table tr").length === 0) {
	// 			await this.getFleetTypes();
	// 		}

	// 		this.setVehiclePrices();
	// 	}
	// }

	addTableRowClickEvent() {
		let elements = this.targetDiv.querySelector("div.vehicles").querySelector("table").querySelectorAll("tr");
		Array.prototype.forEach.call(elements, (row, index) => {
			row.onclick = (e) => {
				//console.log(`row #${index} clicked`);
				const $this = $(e.currentTarget);
				$($this).closest("tr").css("background-color", "gray");
				const vehId = $this.closest("tr").attr("vehicle_id");
				this.microformData.trips[0].vehicles.push({ id: vehId });
				this.goToResForm();
			};
		});
	}

	clearJsonData(direction) {
		this.microformData.trips[0][direction] = {
			additionalFields: {},
			poiName: "",
			address: "",
			metroId: "",
			metroName: "",
			cityName: "",
			stateName: "",
			stateAbr: "",
			zip: "",
			zipId: "",
			countryName: "",
			countryId: "",
			countryAbr: "",
			poiType: "",
			poiId: "",
			poiExtId: "",
			addressVerified: false,
			iata: "",
			airlineId: "",
			FBO: "",
			delay: "",
			lng: "",
			lat: ""
		};
	}

	async findZipId(zip, countryRecordId) {
		console.log(`Search for zipId for ZIP ${zip}...`);
		const result = await this.apiRequest(this.apiUrl + "/microform/find_zip_id", { zip: zip, country_record_id: countryRecordId });
		const zipId = result.data.zip_record_id;
		console.log(`zipId '${zipId}' found`);
		return zipId;
	}

	// Microform start method called from widget.js class IpxMicroform "main" helper method
	async run() {
		this.promise = $.Deferred();

		let retVal = {
			success: true,
			errorMessage: ""
		};

		// load scripts
		await this.loadScript("https://maps.googleapis.com/maps/api/js?key=" + this.googleApiKey + "&libraries=places&language=" + this.language);

		// Get reqUID
		let result = await this.apiRequest(this.apiUrl + "/microform/init");

		// During development!!!
		//axios.defaults.headers.common["api-req"] = "af144a303f0de94a5469d647bcedf5a8";

		// Uncomment this!!!
		if (result.success) {
			console.log("Init successful.");
			axios.defaults.headers.common["api-req"] = result.data.reqUID;
			this.microformData.ada = result.data.ada;
			if (!this.microformData.ada.ada_res_form_url && !IS_DEV) {
				this.microformData.ada.ada_res_form_url = this.baseUrl + '/reservation.php';
			}
			else {
				this.microformData.ada.ada_res_form_url = this.resFormPage;
			}
		}
		else {
			console.log("Error initializing microform.");

			this.targetDiv.querySelector(".ipx-micro-form-container-div").innerHTML = 'Error initializing microform.';

			retVal = {
				success: false,
				errorMessage: result.errorMessage.response.status + " " + result.errorMessage.response.statusText
			};
			this.promise.resolve(retVal);
		}

		// init google address autocompletes
		// variable microformClass is used in functions below where the context is changed. "this" = function"s "this"
		let microformClass = this; // this variable holds class"s context
		let fromElement = this.targetDiv.querySelector("input#from");
		let toElement = this.targetDiv.querySelector("input#to");
		google.maps.event.addListener(new google.maps.places.Autocomplete(fromElement), "place_changed",
			async function () { // here context changes to function"s "this". Below, for toAutocomplate is the same.
				if (microformClass.targetDiv.querySelector(".alert-danger")) {
					microformClass.targetDiv.querySelector(".alert-danger").remove();
				}
				microformClass.fromMustUpdate = true;
				let place = this.getPlace();
				let fromAddressObject = microformClass.extractAddress(place);

				microformClass.microformData.trips[0].from.zip = fromAddressObject.zipCode ? fromAddressObject.zipCode : "";
				microformClass.microformData.trips[0].from.countryName = fromAddressObject.country ? fromAddressObject.country : "";
				microformClass.microformData.trips[0].from.countryAbr = fromAddressObject.countryAbbr ? fromAddressObject.countryAbbr : "";
				microformClass.microformData.trips[0].from.cityName = fromAddressObject.cityName ? fromAddressObject.cityName : "";
				microformClass.microformData.trips[0].from.address = fromAddressObject.streetName ?
					fromAddressObject.streetNumber ? fromAddressObject.streetNumber + " " + fromAddressObject.streetName : "" : "";
				microformClass.microformData.trips[0].from.stateName = fromAddressObject.state ? fromAddressObject.state : "";
				microformClass.microformData.trips[0].from.stateAbr = fromAddressObject.stateAbbr ? fromAddressObject.stateAbbr : "";
				microformClass.microformData.trips[0].from.poiName = fromAddressObject.poiName ? fromAddressObject.poiName : "";
				microformClass.microformData.trips[0].from.lat = fromAddressObject.lat ? fromAddressObject.lat : "";
				microformClass.microformData.trips[0].from.lng = fromAddressObject.lng ? fromAddressObject.lng : "";

				// get google types
				microformClass.googleTypes = "";
				$.each(place.types, function (key, value) {
					microformClass.googleTypes += "'" + value + "',";
				});
				microformClass.googleTypes = microformClass.googleTypes.substr(0, microformClass.googleTypes.length - 1);
				console.log("Google types found: ", microformClass.googleTypes);

				if (microformClass.microformData.trips[0].from.zip === "") {
					console.log("From ZIP not found. Search for cross reference.");
					let result = await microformClass.findCrossReference(fromAddressObject.placeId);
					if (result[0]) {
						console.log("Cross reference found.");
						microformClass.microformData.trips[0].from.zip = result[0].zip;
						microformClass.microformData.trips[0].from.metroId = result[0].metroId;
					}
					else {
						console.log("Cross reference not found.");
						console.log("Search for ZIP by station.");
						let result = await microformClass.apiRequest(microformClass.apiUrl + "/microform/get_zip_by_station",
							{
								station: microformClass.microformData.trips[0].from.cityName,
								state_abbrev: microformClass.microformData.trips[0].from.stateAbr,
								country: microformClass.microformData.trips[0].from.countryAbr,
								station_lat: microformClass.microformData.trips[0].from.lat,
								station_lng: microformClass.microformData.trips[0].from.lng
							});
						if (result.data[0] && result.data[0].zip_number /*&& result.data[0].state_abbrev === microformClass.microformData.trips[0].from.stateAbr*/) {
							microformClass.microformData.trips[0].from.zip = result.data[0].zip_number;
							console.log("Nearest From ZIP - " + microformClass.microformData.trips[0].from.zip);
						}
						else {
							let div = document.createElement('div');
							div.innerHTML = "No service available (From ZIP not found!)";
							div.classList.add("alert");
							div.classList.add("alert-danger");
							microformClass.targetDiv.querySelector("div.footer").insertAdjacentElement('beforebegin', div);
						}
					}
				}
				else {
					console.log("From ZIP found.");
					microformClass.microformData.trips[0].from.zipId = await microformClass.findZipId(
						microformClass.microformData.trips[0].from.zip,
						microformClass.microformData.trips[0].from.countryId
					);
				}

				console.log("finding type match.");
				let poiTypesSearch = await microformClass.apiRequest(microformClass.apiUrl + "/location/poi_type_match", { types: microformClass.googleTypes });
				if (poiTypesSearch.data.length > 0) {
					microformClass.microformData.trips[0].from.poiType = poiTypesSearch.data[0].poi_type_code;
					console.log("Type match found.");
				} else {
					console.log("Type match not found!");
				}

				microformClass.microformData.trips[0].from.placeId = fromAddressObject.placeId;
				microformClass.microformData.trips[0].from.fullName = microformClass.targetDiv.querySelector("input#from").value;

				microformClass.targetDiv.querySelector("input#from").onblur();
			}
		);
		google.maps.event.addListener(new google.maps.places.Autocomplete(toElement), "place_changed",
			async function () { // context changes
				if (microformClass.targetDiv.querySelector(".alert-danger")) {
					microformClass.targetDiv.querySelector(".alert-danger").remove();
				}
				microformClass.toMustUpdate = true;
				let place = this.getPlace();
				let toAddressObject = microformClass.extractAddress(place);

				microformClass.microformData.trips[0].to.zip = toAddressObject.zipCode ? toAddressObject.zipCode : "";
				microformClass.microformData.trips[0].to.countryName = toAddressObject.country ? toAddressObject.country : "";
				microformClass.microformData.trips[0].to.countryAbr = toAddressObject.countryAbbr ? toAddressObject.countryAbbr : "";
				microformClass.microformData.trips[0].to.cityName = toAddressObject.cityName ? toAddressObject.cityName : "";
				microformClass.microformData.trips[0].to.address = toAddressObject.streetName ?
					toAddressObject.streetNumber ? toAddressObject.streetNumber + " " + toAddressObject.streetName : "" : "";
				microformClass.microformData.trips[0].to.stateName = toAddressObject.state ? toAddressObject.state : "";
				microformClass.microformData.trips[0].to.stateAbr = toAddressObject.stateAbbr ? toAddressObject.stateAbbr : "";
				microformClass.microformData.trips[0].to.poiName = toAddressObject.poiName ? toAddressObject.poiName : "";
				microformClass.microformData.trips[0].to.lat = toAddressObject.lat ? toAddressObject.lat : "";
				microformClass.microformData.trips[0].to.lng = toAddressObject.lng ? toAddressObject.lng : "";

				// get google types
				microformClass.googleTypes = "";
				$.each(place.types, function (key, value) {
					microformClass.googleTypes += "'" + value + "',";
				});
				microformClass.googleTypes = microformClass.googleTypes.substr(0, microformClass.googleTypes.length - 1);
				console.log("Google types found: ", microformClass.googleTypes);

				if (microformClass.microformData.trips[0].to.zip === "") {
					console.log("To ZIP not found. Search for cross reference.");
					let result = await microformClass.findCrossReference(toAddressObject.placeId);
					if (result[0]) {
						console.log("Cross reference found.");
						microformClass.microformData.trips[0].to.zip = result[0].zip;
						microformClass.microformData.trips[0].to.metroId = result[0].metroId;
					}
					else {
						console.log("Cross reference not found.");
						console.log("Search for ZIP by station.");
						let result = await microformClass.apiRequest(microformClass.apiUrl + "/microform/get_zip_by_station",
							{
								station: microformClass.microformData.trips[0].to.cityName,
								state_abbrev: microformClass.microformData.trips[0].to.stateAbr,
								country: microformClass.microformData.trips[0].from.countryAbr,
								station_lat: microformClass.microformData.trips[0].from.lat,
								station_lng: microformClass.microformData.trips[0].from.lng
							});
						if (result.data[0] && result.data[0].zip_number /*&& result.data[0].state_abbrev === microformClass.microformData.trips[0].to.stateAbr*/) {
							microformClass.microformData.trips[0].to.zip = result.data[0].zip_number;
							console.log("Nearest To ZIP - " + microformClass.microformData.trips[0].to.zip);
						}
					}
				}
				else {
					console.log("To ZIP found.");
					microformClass.microformData.trips[0].to.zipId = await microformClass.findZipId(
						microformClass.microformData.trips[0].to.zip,
						microformClass.microformData.trips[0].to.countryId
					);
				}

				console.log("finding type match.");
				let poiTypesSearch = await microformClass.apiRequest(microformClass.apiUrl + "/location/poi_type_match", { types: microformClass.googleTypes });
				if (poiTypesSearch.data.length > 0) {
					microformClass.microformData.trips[0].to.poiType = poiTypesSearch.data[0].poi_type_code;
					console.log("Type match found.");
				} else {
					console.log("Type match not found!");
				}

				microformClass.microformData.trips[0].to.placeId = toAddressObject.placeId;
				microformClass.microformData.trips[0].to.fullName = microformClass.targetDiv.querySelector("input#to").value;

				microformClass.targetDiv.querySelector("input#to").onblur();
			}
		);

		// set events

		this.targetDiv.querySelector("input#from").onblur = async () => {
			this.onFromBlur();
		};

		this.targetDiv.querySelector("input#from").onkeyup = async (e) => {
			let value = $(e.target).val();
			let clearBtn = $(e.target).next(".clear");
			if (value === "") {
				console.log("From cleared.");
				$(clearBtn).trigger("click");
			}
		};

		this.targetDiv.querySelector(" input#to").onblur = async () => {
			this.onToBlur();
		};

		this.targetDiv.querySelector("input#to").onkeyup = async (e) => {
			let value = $(e.target).val();
			let clearBtn = $(e.target).next(".clear");
			if (value === "") {
				console.log("TO cleared.");
				$(clearBtn).trigger("click");
			}
		};

		this.targetDiv.querySelector(".from-clear").onclick = async (e) => {
			if (this.targetDiv.querySelector(".alert-danger")) {
				this.targetDiv.querySelector(".alert-danger").remove();
			}
			let $this = $(e.currentTarget);
			this.targetDiv.querySelector("input#from").value = "";
			let direction = $this.attr("direction");
			this.clearJsonData(direction);
			
			this.targetDiv.querySelector("div.vehicles").innerHTML = `
				<div class="w3_main_grid_right">
					<div></div>
				</div>
			`;
			this.fromMustUpdate = true;
		};

		this.targetDiv.querySelector(".to-clear").onclick = async (e) => {
			if (this.targetDiv.querySelector(".alert-danger")) {
				this.targetDiv.querySelector(".alert-danger").remove();
			}
			let $this = $(e.currentTarget);
			this.targetDiv.querySelector("input#to").value = "";
			let direction = $this.attr("direction");
			this.clearJsonData(direction);

			let prices = this.targetDiv.querySelectorAll("div.vehicles td.price_per_vehicle");
			Array.prototype.forEach.call(prices, (row) => {
				row.innerHTML = "";
			});
			
			this.toMustUpdate = true;
		};

		this.targetDiv.querySelector("input#microform-continue-btn").onclick = () => {
			this.goToResForm();
		};
		
		this.targetDiv.querySelector(".swap-btn i").onclick = async () => {

			if (this.microformData.trips[0].from.zip === "" || this.microformData.trips[0].to.zip === "") {
				return;
			}

			console.log("Swap FROM & TO");
			// swap input values
			let fromInputValue = this.targetDiv.querySelector("input#from").value;
			this.targetDiv.querySelector("input#from").value = this.targetDiv.querySelector("input#to").value;
			this.targetDiv.querySelector("input#to").value = fromInputValue;
			// swap JSON
			let from = microformClass.microformData.trips[0].from;
			microformClass.microformData.trips[0].from = microformClass.microformData.trips[0].to;
			microformClass.microformData.trips[0].to = from;
			// trigger recalculations
			this.fromMustUpdate = true;
			this.toMustUpdate = true;
			await this.getFleetTypes();
			await this.onFromBlur();
		};

		//Krasi JS

		this.targetDiv.querySelector(".info-icon").onclick = function (e) {
			e.stopPropagation();

			let state = microformClass.targetDiv.querySelector(".info-tooltip").style.display;
			if (state === "none") {
				microformClass.targetDiv.querySelector(".info-tooltip").style.display = "block";
			} else {
				microformClass.targetDiv.querySelector(".info-tooltip").style.display = "none";
			}
		};

		//Krasi JS End

		// Handle init parameters
		if (this.fromPoi && this.fromPoi !== "") {
			console.log("fromPoi parameter found.");
			let poiDetails = await this.getPoiDetails(this.fromPoi);
			microformClass.fromMustUpdate = true;
			microformClass.microformData.trips[0].from.zip = poiDetails["loc_id"];
			microformClass.microformData.trips[0].from.zip = poiDetails["zip"];
			microformClass.microformData.trips[0].from.countryName = poiDetails["countryName"];
			microformClass.microformData.trips[0].from.countryAbr = poiDetails["countryAbr"];
			microformClass.microformData.trips[0].from.cityName = poiDetails["loc_id"];
			microformClass.microformData.trips[0].from.address = poiDetails["address"];
			microformClass.microformData.trips[0].from.stateName = poiDetails["stateName"];
			microformClass.microformData.trips[0].from.stateAbr = poiDetails["stateAbr"];
			microformClass.microformData.trips[0].from.poiName = poiDetails["poi_known_as"];
			microformClass.microformData.trips[0].from.lat = poiDetails["lat"];
			microformClass.microformData.trips[0].from.lng = poiDetails["lng"];
			microformClass.microformData.trips[0].from.metroId = poiDetails["metroId"];
			microformClass.microformData.trips[0].from.poiType = poiDetails["poiType"];
			microformClass.microformData.trips[0].from.fullName = poiDetails["poi_known_as"];
			microformClass.microformData.trips[0].from.poiId = poiDetails["poi_id"];																		

			this.targetDiv.querySelector("input#from").value = microformClass.microformData.trips[0].from.poiName;

			this.onFromBlur();
		}

		if (this.toPoi && this.toPoi !== "") {
			console.log("toPoi parameter found.");
			let poiDetails = await this.getPoiDetails(this.toPoi);
			microformClass.toMustUpdate = true;
			microformClass.microformData.trips[0].to.zip = poiDetails["loc_id"];
			microformClass.microformData.trips[0].to.zip = poiDetails["zip"];
			microformClass.microformData.trips[0].to.countryName = poiDetails["countryName"];
			microformClass.microformData.trips[0].to.countryAbr = poiDetails["countryAbr"];
			microformClass.microformData.trips[0].to.cityName = poiDetails["loc_id"];
			microformClass.microformData.trips[0].to.address = poiDetails["address"];
			microformClass.microformData.trips[0].to.stateName = poiDetails["stateName"];
			microformClass.microformData.trips[0].to.stateAbr = poiDetails["stateAbr"];
			microformClass.microformData.trips[0].to.poiName = poiDetails["poi_known_as"];
			microformClass.microformData.trips[0].to.lat = poiDetails["lat"];
			microformClass.microformData.trips[0].to.lng = poiDetails["lng"];
			microformClass.microformData.trips[0].to.metroId = poiDetails["metroId"];
			microformClass.microformData.trips[0].to.poiType = poiDetails["poiType"];
			microformClass.microformData.trips[0].to.fullName = poiDetails["poi_known_as"];
			microformClass.microformData.trips[0].to.poiId = poiDetails["poi_id"];																	  

			this.targetDiv.querySelector("input#to").value = microformClass.microformData.trips[0].to.poiName;

			this.onToBlur();
		}

		this.promise.resolve(retVal);

		return this.promise;
	}
}
