// Class containing all needed data for the microform
export default class MicroformData {

    constructor() {
        this.ada = undefined;
        this.progress = {
            step: 'from-microform',
            trip: 1,
            segment: 'pkp'
        };
        this.language = 1;
        this.languageAbr = 'en';
        this.currency = 100;
        this.user = {
            currency: {
                id: 100,
                name: 'Unated States Dollar',
                symbol: '$',
                abbr: 'USD'
            },
            language: {
                language: navigator.language,
                languages: navigator.languages
            },
            IP: undefined,
            host: undefined,
            browser: navigator.userAgent,
            geolocation: undefined
        };
        this.trips = [{
            from: {
                locId: '',
                additionalFields: {},
                poiName: '',
                address: '',
                metroId: '',
                metroName: '',
                cityName: '',
                stateName: '',
                stateAbr: '',
                zip: '',
                zipId: '',
                countryName: '',
                countryId: '221',
                countryAbr: '',
                poiType: '',
                poiId: '',
                poiExtId: '',
                addressVerified: false,
                iata: '',
                airlineId: '',
                FBO: '',
                delay: '',
                lng: '',
                lat: '',
                placeId: '',
                fullName: ''
            },
            to: {
                locId: '',
                additionalFields: {},
                poiName: '',
                address: '',
                metroId: '',
                metroName: '',
                cityName: '',
                stateName: '',
                stateAbr: '',
                zip: '',
                zipId: '',
                countryName: '',
                countryId: '221',
                countryAbr: '',
                poiType: '',
                poiId: '',
                poiExtId: '',
                addressVerified: false,
                iata: '',
                airlineId: '',
                FBO: '',
                delay: '',
                lng: '',
                lat: '',
                placeId: '',
                fullName: ''
            },
            pickupTime: {
                confirmed: false,
                date: '',
                year: undefined,
                month: undefined,
                day: undefined,
                hour: '',
                minute: '',
                spu_time: ''
            },
            dropOffTime: {},
            service: 'Transfer',
            serviceDuration: 0,
            serviceOccasion: '',
            stops: [],
            passenger: {
                name: '',
                phone: '',
                numberOfPassengers: 0,
                infant: 0,
                booster: 0,
                instructions: ''
            },
            vehicles: [],
            totalTip: 0,
            rsp: '',
            tripInfo: {
                rspMeasureUnit: '',
                distance: {
                    totalDistanceKm: 0,
                    totalDistanceMiles: 0,
                    totalDurationMins: 0
                },
                route: [],
                tripModifiers: [],
                totals: {
                    tripSubTotal: 0,
                    tripTotal: 0
                },
                tipIncluded: false,
                microform: true
            },
            completed: false,
			status: 1 //obj.tripStatus.SCHEDULED
        }];
        this.client = {
            name: '',
            phone: '',
            email: '',
            addr: '',
            city: '',
            state: '',
            zip: '',
            country: ''
        };
        this.payment = {};
        this.contact = {};
        this.confirm = {};
        this.totals = {
            discount: "",
            discountDescription: "",
            promoCodeText: "",
            promoCode: "",
            reservationSubTotal: "",
            reservationTotal: "",
            reservationTotalTip: ""
        };
        this.googleTypes = '';
    }
}
