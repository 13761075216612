// Load application styles

export default class Templates {

	constructor(baseUrl, apiUrl) {
        this.baseUrl = baseUrl;
        this.apiUrl = apiUrl;
	}

	// Getters
    get content() {
        return `<link rel="manifest" href="manifest.json">
                <div class="ipx-micro-form-container-div">
                    <div  class="w3layouts_main_grid">
                        <div class="w3_agileits_main_grid w3l_main_grid">
	                        <span class="agileits_grid">
		                        <label>FROM </label>
		                        <input type="text" id="from" name="from" placeholder="Start Location..." required=""> 
		                        <i class="from-clear fa fa-times fa-lg" direction="from" aria-hidden="true"></i>
	                        </span>
                        </div>
                        <div class="swap-btn">
	                        <i class="fa fa-retweet" aria-hidden="true"></i>
                        </div>
                        <div class="w3_agileits_main_grid w3l_main_grid to-div"> 
	                        <span class="agileits_grid">
		                        <label>TO </label>
		                        <input type="text" id="to" name="to" placeholder="Destination Location... " required=""> 
		                        <i class="to-clear fa fa-times fa-lg" direction="to" aria-hidden="true"></i>
	                        </span>
                        </div>

                        <div class="w3_main_grid vehicles">
	                        <div class="w3_main_grid_right">
		                        <div></div>
	                        </div>
                        </div>

                        <div class="w3_main_grid">
	                        <div class="info-tooltip" style="display: none;">
							    <div class="row">
							        <div class="col-md-6">Service Provided by:</div>
							        <div class="col-md-6"><img src="https://www.triptender.net/img/Interplex-Global-Transportation-logo-100x20.gif"></div>
                                </div>
                                <div class="row">
							        <div class="col-md-6">Online booking by: </div>
							        <div class="col-md-6"><img src="https://www.triptender.net/img/triptender_logo_100px_1.jpg"></div>
                                </div>
	                        </div>
	                        <div class="w3_main_grid_right">
                                <div class="info-icon-div">
                                    <i class="fa fa-info-circle info-icon" aria-hidden="true" id="info-btn"></i>
                                </div>
		                        <div>
		                            <div style="text-align: right">
		                                <input type="submit" id="microform-continue-btn" value="Continue">
                                    </div>
                                </div>
		                        
	                        </div>
                        </div>

                        <br />

                        <div class="footer"></div>

                    </div>
                </div>`;
    }

    get loader() {
        return `<div id="loader" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.1); display: none;">
                    <div style="display: block;
                                position: relative;
                                left: 50%;
                                top: 50%;
                                width: 100px;
                                height: 100px;
                                margin: -50px 0 0 -50px;
                                background-position: center; 
                                background-repeat: no-repeat; 
                                background-size: cover; 
                                background-image: url('https://www.triptender.net/images/microform/loader.gif');
                                z-index: 999;">
                    </div>
                </div >`;
    }
}
